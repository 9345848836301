import { Link } from '@remix-run/react';

import { GlobalPairingRoundStatus } from '../../../types';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ShuffleIcon } from '../../icons/ShuffleIcon';
import { useOrgContext } from '../../Organization/Details/Context';
import { useGlobalPairingRoundDetails } from '../GlobalRound';
import { PairingParticipantsEditModal } from '../PairingParticipantModal';
import { PairingRoundTable } from './Table';

const PairingRoundListHeader = (): JSX.Element => {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  const { org, updateOrg } = useOrgContext();

  const handleEditingParticipants = async () => {
    await triggerFullScreenModal({
      kind: 'custom',
      element: (p) => (
        <PairingParticipantsEditModal
          org={org}
          onCancel={p.internalOnCancel}
          onSave={(pairingDisabledParticipantsCount) => {
            updateOrg({
              pairingDisabledParticipantsCount,
            });
            p.internalOnConfirm();
          }}
        />
      ),
    });
  };

  return (
    <header className='flex-shrink-0 w-full flex items-center justify-between pl-1.5'>
      <div className='flex flex-col'>
        <div className='flex items-center gap-2'>
          <ShuffleIcon className='w-5 h-5 fill-current' />
          <h1 className='font-medium text-2xl'>Random Pairing Rounds</h1>
        </div>

        <div className='flex items-center gap-3'>
          <p className='font-medium text-secondary'>
            {org.organizersCount - org.pairingDisabledParticipantsCount}/
            {org.organizersCount} Participants
          </p>
          <button
            type='button'
            className='btn text-primary'
            onClick={handleEditingParticipants}
          >
            Edit
          </button>
        </div>
      </div>
    </header>
  );
};

export const PairingRoundListInOrgDetails = (): JSX.Element => {
  const { org } = useOrgContext();

  return (
    <div className='w-full h-full pl-8 pt-10 pr-12.5'>
      <PairingRoundListHeader />

      <PairingRoundTable
        params={{
          orgId: org.id,
          orderBy: 'startedAt',
        }}
        columns={{
          channel: true,
          createdAt: true,
          globalStatus: true,
          startedEndedAt: true,
          gamePack: true,
          completedPairings: true,
          completedPlayers: true,
          lastPlayedAt: true,
        }}
      />
    </div>
  );
};

export const PairingRoundListInGlobalRound = (): JSX.Element => {
  const { globalRound } = useGlobalPairingRoundDetails();

  return (
    <div className='w-full h-full px-10 flex flex-col gap-5'>
      <div className='text-2xl font-medium text-icon-gray'>
        <Link to={'/admin/programs/global-pairing-rounds'}>
          Global Pairs Config
        </Link>{' '}
        /{' '}
        <Link to={'../'} relative='path'>
          {globalRound.mainGamePack?.name}
        </Link>{' '}
        / <span className='text-white'>Rounds</span>
      </div>

      <div className='text-base font-bold text-red-600 mb-2'>
        {globalRound.status === GlobalPairingRoundStatus.Configuring &&
          'Round configuration in progress. This will take a few minutes.'}
      </div>

      <PairingRoundTable
        params={{
          globalRoundId: globalRound.id,
          orderBy: 'orgName',
        }}
        columns={{
          channel: true,
          organization: true,
          status: true,
          completedPairings: true,
          completedPlayers: true,
          participation: true,
          lastPlayedAt: true,
          actionSheet: true,
        }}
      />
    </div>
  );
};
